<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vs-select
            v-model="selectedLocation"
            placeholder="Filter by venue"
            >
              <vs-select-item :key="index" :value="item._id" :text="item.name" v-for="item,index in locationlist" />
            </vs-select>
          <!-- <vs-button class="mb-4 md:mb-0" type="border" @click="openFilterByLocationPopUp()"
            >Filter by location</vs-button
          > -->
          <vs-select
            v-model="selectedFilterUser"
            placeholder="Filter by user"
            >
              <vs-select-item :key="index" :value="item._id" :text="item.username" v-for="item,index in userlist" />
            </vs-select>
          <!-- <vs-button class="mb-4 md:mb-0" type="border" @click="openFilterByUserPopUp()"
            >Filter by user</vs-button
          > -->
          <vs-button class="mb-4 md:mb-0" type="border" @click="showAll()"
            >Show All</vs-button
          >

          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>
        <template slot="thead">
          <vs-th sort-key="location.name">Venue</vs-th>
          <vs-th sort-key="user.username">Recommended By</vs-th>
          <vs-th sort-key="comment">Comment</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">

          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].location.name">{{ data[indextr].location.name }}</vs-td>
            <vs-td :data="data[indextr].user.username">{{ data[indextr].user.username }}</vs-td>
            <vs-td :data="data[indextr].comment">{{ data[indextr].comment }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(parseInt(data[indextr].createdAt)).format("DD/MM/y")
            }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <feather-icon
                    icon="Edit3Icon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="editDetailHandler(data[indextr]._id)"
                  />
                </vx-tooltip>
                <!-- <vx-tooltip text="Delete" position="top" delay=".3s">
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                  />
                </vx-tooltip> -->
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ dataTableParams.page * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  
    <!-- <vs-popup class="holamundo"  title="Filter by location" button-close-hidden='true' :active.sync="filterByLocationPopUp">
            <vs-select
            label="Select venue"
            v-model="selectedLocation"
            >
              <vs-select-item :key="index" :value="item._id" :text="item.name" v-for="item,index in locationlist" />
            </vs-select>
            <vs-button @click="searchByLocation()" color="primary" type="filled">Search</vs-button>
    </vs-popup>
        <vs-popup class="holamundo mt-2"  title="Filter by user" button-close-hidden='true' :active.sync="filterByUserPopUp">
      <vs-select
            label="Select user"
            v-model="selectedFilterUser"
            >
              <vs-select-item :key="index" :value="item._id" :text="item.username" v-for="item,index in userlist" />
            </vs-select>
            <vs-button @click="searchByUser()" color="primary" type="filled">Search</vs-button>
    </vs-popup> -->
  </vx-card>
  
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import {recommendationMixin} from "../../mixins/recommendationMixin"

export default {
  components: {
    "v-select": vSelect,
  },
  mixins: [ recommendationMixin ],
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        page: 1,
        limit: 30,
        userId: null,
        locationId:null
      },
      users: [],
      limitOptions: [30, 50, 100, 150],
      selectedUser: {},
      selectedIndex: 0,
      filterByLocationPopUp: false,
      filterByUserPopUp: false,
      filterPaginationData:{
        limit: 10,
        page: 1,
        searchKey: ""
      },
      locationlist: [],
      userlist: [],
      selectedLocation:'',
      selectedFilterUser: ''
    };
  },
  methods: {
    // ...mapActions("admin", ["fetchAdmins", "deleteAdmin"]),
    moment(date) {
      return moment(date);
    },
    // openFilterByLocationPopUp(){
    //   this.filterByLocationPopUp = true
    //   this.getLocations(this.filterPaginationData).then(res => {
    //     this.locationlist = res
    //   })
    // },
    // openFilterByUserPopUp(){
    //   this.filterByUserPopUp = true
    //   this.getUsers(this.filterPaginationData).then(res => {
    //     this.userlist = res
    //   })
    // },
    searchByLocation(){
      this.dataTableParams.locationId = this.selectedLocation
      this.dataTableParams.userId = null
      this.getUsersList()
    },
    searchByUser(){
      this.dataTableParams.userId = this.selectedFilterUser
      this.dataTableParams.locationId = null
      this.getUsersList()

    },
    showAll(){
      this.dataTableParams.userId = null
      this.dataTableParams.locationId = null
      this.dataTableParams.page = 1
      this.getUsersList()
    },
    getUsersList() {
      let self = this;
      this.getRecommendations(self.dataTableParams).then((res) => {
        self.users = res.recommendations;
        self.totalDocs = res.pagination.total;
        self.page = res.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getUsersList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "LocationRecommendationDetails", params: { id: id } });
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "AdminDetail", params: { id: id } });
    },
    addDetailHandler() {
      this.$router.push({ name: "AddAdminDetail" });
    },
    // deleteDetailHandler(id, i) {
    //   console.log(id, i);
    //   this.deleteAdmin(id).then((res) => {
    //     this.$vs.notify({
    //       title: "User Deleted",
    //       text: res.data.message,
    //       iconPack: "feather",
    //       icon: "icon-alert-circle",
    //       color: "success",
    //     });
    //     this.getUsersList();
    //     // this.users.splice(i, 1);
    //   });
    // },

    deleteDetailHandler(user, i) {
      this.selectedUser = user;
      this.selectedIndex = i;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${user.firstName}"`,
        accept: this.confirmDeleteRecord,
        acceptText: "Delete",
      });
    },
    confirmDeleteRecord() {
      this.deleteAdmin(this.selectedUser._id).then((res) => {
        this.$vs.notify({
          title: "Admin Deleted",
          text: res.message.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.users.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getUsersList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getUsersList();
      }
    },
    "selectedLocation": function (newVal, oldVal) {
      if(newVal !== oldVal) {
        this.selectedLocation = newVal;
        this.searchByLocation();
      }
    },
    "selectedFilterUser": function(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.selectedFilterUser = newVal;
        this.searchByUser();
      }
    }
  },
  created() {

    this.getUsersList();
    this.getLocations(this.filterPaginationData).then(res => {
      this.locationlist = res
    });
    this.getUsers(this.filterPaginationData).then(res => {
      this.userlist = res
    });
  },
};
</script>
